<template>
  <article class="c-article">
    <header class="c-article__header">
      <div class="l-container">
        <h1 class="c-article__title" v-t="'よくあるご質問'" />
      </div>
    </header>
    <div class="c-article__content">
      <div class="l-container">
        <div class="p-faq">
          <CAccordion
            v-for="category of list"
            :key="category.code"
            class="p-faq__accordion"
            :label="category.title"
          >
            <ul class="p-faq__list">
              <li
                v-for="subCategory in category.subCategory"
                :key="subCategory.code"
                class="p-faq__item"
              >
                <app-link
                  :to="{
                    name: 'FaqDetail',
                    params: {
                      portalId,
                      subCategory: subCategory.code,
                    },
                  }"
                  v-text="subCategory.title"
                  class="p-faq__link"
                ></app-link>
              </li>
            </ul>
          </CAccordion>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import CAccordion from '@/components/common/CAccordion.vue'

export default {
  name: 'FaqPresentation',
  metaInfo: {
    htmlAttrs: {
      class: '-background-white',
    },
  },
  components: {
    CAccordion,
  },
  props: {
    faqList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    titleKey() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'titleEnglish'
        default:
          return 'title'
      }
    },
    list() {
      return this.faqList.map((data) => ({
        title: data[this.titleKey],
        code: data.code,
        subCategory: data.subCategory.map((data) => ({
          title: data[this.titleKey],
          code: data.code,
        })),
      }))
    },
  },
}
</script>
