<template>
  <section class="c-accordion">
    <component :is="tag" class="c-accordion__heading">
      <button
        type="button"
        :id="toggleId"
        class="c-accordion__toggle"
        :aria-expanded="expanded"
        :aria-controls="regionId"
        @click="handleClickToggle()"
      >
        <span class="c-accordion__label">
          <slot name="label">
            {{ label }}
          </slot>
        </span>
      </button>
    </component>
    <div
      ref="region"
      role="region"
      :id="regionId"
      class="c-accordion__region"
      :aria-labelledby="toggleId"
      hidden
    >
      <div class="c-accordion__content">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'CAccordion',
  props: {
    level: {
      type: String,
      validate: (text) => ['1', '2', '3', '4', '5', '6'].includes(text),
      default: '2',
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    tag() {
      return `h${this.level}`
    },
    uid() {
      return this._uid || Math.random().toString(36).replace('0.', '')
    },
    toggleId() {
      return `accordion-toggle-${this.uid}`
    },
    regionId() {
      return `accordion-region-${this.uid}`
    },
    region() {
      return this.$refs.region
    },
  },
  methods: {
    handleClickToggle() {
      this.expanded = !this.expanded

      this.region.hidden = false

      gsap.fromTo(
        this.region,
        {
          height: !this.expanded ? this.region.scrollHeight : 0,
        },
        {
          height: this.expanded ? this.region.scrollHeight : 0,
          duration: 0.3,
          ease: 'power4.out',
          onComplete: () => {
            this.region.style.height = ''
            if (!this.expanded) this.region.hidden = true
          },
        }
      )
    },
  },
}
</script>
