var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"c-article"},[_c('header',{staticClass:"c-article__header"},[_c('div',{staticClass:"l-container"},[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('よくあるご質問'),expression:"'よくあるご質問'"}],staticClass:"c-article__title"})])]),_c('div',{staticClass:"c-article__content"},[_c('div',{staticClass:"l-container"},[_c('div',{staticClass:"p-faq"},_vm._l((_vm.list),function(category){return _c('CAccordion',{key:category.code,staticClass:"p-faq__accordion",attrs:{"label":category.title}},[_c('ul',{staticClass:"p-faq__list"},_vm._l((category.subCategory),function(subCategory){return _c('li',{key:subCategory.code,staticClass:"p-faq__item"},[_c('app-link',{staticClass:"p-faq__link",attrs:{"to":{
                  name: 'FaqDetail',
                  params: {
                    portalId: _vm.portalId,
                    subCategory: subCategory.code,
                  },
                }},domProps:{"textContent":_vm._s(subCategory.title)}})],1)}),0)])}),1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }