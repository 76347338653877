<template>
  <FaqPresentation :faq-list="faqList" />
</template>

<script>
import { defaultLayoutViewMixin } from '@/mixins'
import FaqPresentation from '@/components/faq/FaqPresentation.vue'
import { getFaqCsv } from '@/assets/request/csv/getFaqCsv'

export default {
  name: 'Faq',
  mixins: [defaultLayoutViewMixin],
  components: {
    FaqPresentation,
  },
  metaInfo() {
    return {
      title: this.$t('よくあるご質問'),
    }
  },
  async beforeRouteEnter(_to, _from, next) {
    const result = await getFaqCsv()

    if (result.isError) {
      next({ name: 'Error' })
      return
    }

    next((mv) => {
      mv.setFaqList(result.data.list)
    })
  },
  data() {
    return {
      faqList: [],
    }
  },
  methods: {
    setFaqList(list) {
      this.faqList = list
    },
  },
}
</script>
